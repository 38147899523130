import { AnalyticsBrowser } from '@segment/analytics-next'
import Vue from 'vue'

export default async function segmentPlugin({ app }, inject) {
  try {
    const options = JSON.parse('{"writeKey":"G0ZVd0M0LRnDivWAZFu35cXDExfjaPF7","pageCategory":null,"additionalProperties":{"source":"pen-builder"},"domain":"penbox.io","app":"builder"}')

    const analytics = AnalyticsBrowser.load(
      {
        writeKey: options.writeKey,
        cdnURL: `/core/segment`,
      },
      {
        integrations: {
          'Segment.io': {
            // This implies that there is a /core proxy defined in nuxt.config.js
            apiHost: `${options.app}.${options.domain}/core/analytics`,
          },
        },
      }
    )

    if (options.additionalProperties) {
      void analytics.addSourceMiddleware(({ payload, next }) => {
        try {
          payload.obj.properties = {
            ...payload.obj.properties,
            ...options.additionalProperties,
          }
        } finally {
          next(payload)
        }
      })
    }

    if (app.router) {
      const trackRoute = (route = null) => {
        if (!route?.name) return

        const category = options.pageCategory || '' // TODO: from route meta ?
        const name = route.name || ''
        const properties = { path: route.fullPath }

        void analytics.page(category, name, properties)
      }

      app.router.afterEach(trackRoute)
      trackRoute(app.router.currentRoute)
    }

    app.$analytics = analytics

    Vue.mixin({
      beforeCreate() {
        Object.defineProperty(this, '$analytics', {
          value: createChildAnalytics(this, analytics),
          writable: false,
          configurable: true,
        })
      },
    })
  } catch (error) {
    console.error('Failed to load Segment', error)
  }
}

function createChildAnalytics(vm, analytics) {
  const $analytics = vm.$options.parent?.$analytics ?? analytics

  const options = vm.$options.segment
  if (options === true || options === undefined) return $analytics
  if (options === false || options === null) return null

  const wrapper = {}
  for (const k in $analytics) {
    if (typeof $analytics[k] === 'function') {
      wrapper[k] = $analytics[k].bind($analytics)
    }
  }

  const { properties } = options
  if (properties) {
    const mergeProperties = (data) => {
      const props = typeof properties === 'function' ? properties.call(vm) : properties
      return { ...props, ...data }
    }

    wrapper.page = (category, page, data, ...args) =>
      $analytics.page(category, page, mergeProperties(data), ...args)
    wrapper.screen = (category, page, data, ...args) =>
      $analytics.screen(category, page, mergeProperties(data), ...args)
    wrapper.track = (event, data, ...args) =>
      $analytics.track(event, mergeProperties(data), ...args)
  }

  return wrapper
}
