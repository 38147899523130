<template lang="pug">

v-app(style="background-color: white")
  v-app-bar(app color="white" flat)
    v-img.flex-grow-0.mr-2.hidden-xs-only(:src="require('~/assets/logo.svg')" height="36" width="150" alt="Penbox logo")
    v-img.flex-grow-0.mr-2.hidden-sm-and-up(:src="require('~/assets/icon.svg')" height="36" width="36" alt="Penbox icon")
    v-spacer
    v-btn.hidden-xs-only(color="primary" outlined @click="bookDemo") {{ $t('book_demo_btn') }}
    v-btn.ml-4(color="primary" @click="signup") {{ $t('start_for_free_btn') }}
  v-main(style="max-height:100vh")
    //- @hack: https://github.com/nuxt/nuxt.js/pull/9858
    nuxt(v-if="nuxt.err")
    nuxt-child(v-else v-bind="{ useStrict }")

</template>

<style lang="sass" scoped>
.v-main
  max-height: 100vh
</style>
<i18n lang="yaml">
en:
  book_demo_btn: Book a demo
  start_for_free_btn: Start for free
</i18n>
<script>
import Vue from 'vue'

export default {
  data() {
    return {
      useStrict: false,
    }
  },

  beforeCreate() {
    Vue.util.defineReactive(this, 'nuxt', this.$root.$options.nuxt)
  },
  methods: {
    signup() {
      void this.$analytics.track('Sign up for free')
      window.open('https://app.penbox.io/signup')
    },
    bookDemo() {
      void this.$analytics.track('Book a demo')
      window.open('https://penbox.io/get-started')
    },
  },
}
</script>
