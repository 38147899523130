<template lang="pug">

v-app
  v-app-bar(app color="white")
    nuxt-link(to="/")
      v-img.flex-grow-0.mr-2.hidden-xs-only(:src="require('~/assets/logo.svg')" height="36" width="150" alt="Penbox logo")
      v-img.flex-grow-0.mr-2.hidden-sm-and-up(:src="require('~/assets/icon.svg')" height="36" width="36" alt="Penbox icon")
    v-spacer
    v-btn(text color="primary" nuxt to="/") JSON-expression
    v-btn(text color="primary" nuxt to="/flow") Flow

    v-menu(:close-on-content-click="false" bottom offset-y left)
      template(#activator="{ on, attrs, value }")
        v-btn(v-on="on" v-bind="attrs" icon)
          v-icon {{ $options.icons.mdiDotsVertical }}
      template(#default)
        v-list
          v-tooltip(bottom)
            template(#activator="{ on, attrs }")
              v-list-item(v-on="on" v-bind="attrs" @click="useStrict = !useStrict")
                v-list-item-action
                  v-switch(v-model="useStrict" @click.stop)
                v-list-item-content
                  v-list-item-title Strict mode
            template(#default)
              | Legacy features are being dropped from time to time. Do not rely on them.

  v-main
    //- @hack: https://github.com/nuxt/nuxt.js/pull/9858
    nuxt(v-if="nuxt.err")
    nuxt-child(v-else v-bind="{ useStrict }")

</template>

<script>
import Vue from 'vue'
import { mdiDotsVertical } from '@mdi/js'

export default {
  icons: {
    mdiDotsVertical,
  },

  data() {
    return {
      useStrict: false,
    }
  },

  beforeCreate() {
    Vue.util.defineReactive(this, 'nuxt', this.$root.$options.nuxt)
  },
}
</script>
