<template lang="pug">

v-container.d-flex.justify-center.flex-column(fluid fill-height)
  v-icon(x-large color="error") {{ mdiInformationOutline }}

  h2.my-5.headline {{ message }}

  p.body-2(v-if="statusCode === 404")
    nuxt-link(to="/") Back to the home page
  p.body-2(v-else)
    | An error occurred while rendering the page. Check developer tools console for details.

</template>

<script>
import { mdiInformationOutline } from '@mdi/js'

export default {
  name: 'nuxt-error',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data: (vm) => ({
    mdiInformationOutline,
  }),
  head() {
    return {
      title: this.message,
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    message() {
      return this.error.message || `Error`
    },
  },
  watch: {
    error: {
      immediate: true,
      handler(err) {
        if (err.isAxiosError) return // Should be handled by interceptor

        this.$sentry?.withScope((scope) => {
          const statusCode = err.statusCode ?? err.status
          if (statusCode >= 400 && statusCode < 500) scope.setLevel('warning')

          if (err instanceof Error) this.$sentry.captureException(err)
          else this.$sentry.captureEvent({ message: err.message, extra: err, level: 'fatal' })
        })
      },
    },
  },
}
</script>
