const shadeHexColor = (color: string, percent: number) => {
  const f = parseInt(color.slice(1), 16)
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent
  const R = f >> 16
  const G = (f >> 8) & 0x00ff
  const B = f & 0x0000ff

  const hexCode = (
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)

  return `#${hexCode}`
}

type Variations = 'base' | `${'lighten' | 'darken'}${'1' | '2' | '3' | '4' | '5'}`
export const genVuetifyVariations = (value: string): Record<Variations, string> => {
  const values: Record<string, string> = { base: value }

  for (let i = 5; i > 0; --i) {
    values[`lighten${i}`] = shadeHexColor(value, (1 / (5 + 1)) * i)
  }

  for (let i = 1; i <= 4; ++i) {
    values[`darken${i}`] = shadeHexColor(value, -1 * (1 / (4 + 1)) * i)
  }

  return values
}
